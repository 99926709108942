<template>
  <div class="segment specialists-search">
    <SpecialistsV2 />
  </div>
</template>

<script>
import SpecialistsV2 from './components/SpecialistsV2'

export default {
  name: 'Specialists',
  components: {
    SpecialistsV2
  }
}
</script>

<style lang="sass" scoped>
.specialists-search
  margin-top: 57px
  height: 100%
  min-height: calc(100vh - 112px)
</style>
