<template>
  <div>
    <div class="segment-content">
      <!-- specialist count -->
      <div class="heading-small margin-bottom">
        {{ resultsCount }}
      </div>

      <!-- specialists -->
      <template v-if="results.length && !localLoading">
        <template v-for="(specialist, index) of results">
          <QuestionnaireBanner
            class="margin-bottom-sm"
            origin="Specialists"
            :key="specialist.id"
            :title="$translations.search.banner.title"
            :button-text="$translations.search.banner.button"
            v-if="index === 0"
          />

          <SpecialistCard
            :key="index"
            :specialist="specialist"
            :show-banner="bannerValidation(index)"
          />
        </template>
        <LoaderSimple class="margin-y" v-if="loaders.newPage" />
      </template>

      <template v-else-if="!localLoading">
        <DataZeroSimple
          :text="$translations.search['no-specialists']"
          :image="require('@/assets/icons/search/no-specialists.svg')"
        />
        <Button
          type="outline"
          :text="$translations.search['clean-filters']"
          @clicked="reset"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LoaderSimple, Button } from '@seliaco/red-panda'

import SpecialistsListMixinV2 from '@/mixins/specialists-list-mixin-v2'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import SpecialistCard from '@/components/cards/specialist-card/SpecialistCardWrapper.vue'
import QuestionnaireBanner from '@/components/banner/QuestionnaireBanner.vue'
import { SpecialistSearch } from '@/types/events'

export default {
  name: 'SpecialistsV2',
  components: {
    DataZeroSimple,
    SpecialistCard,
    LoaderSimple,
    Button,
    QuestionnaireBanner
  },
  mixins: [SpecialistsListMixinV2],
  data () {
    return {
      startTime: null,
      localLoading: false
    }
  },
  created () {
    const scrollPosition = localStorage.getItem('scrollPosition')

    if (scrollPosition && this.results?.length !== 0) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(scrollPosition))
        localStorage.removeItem('scrollPosition')
      }, 100)
    } else {
      this.$store.dispatch('filtersV2/init', { cleanedRequest: true })
    }

    this.onCreated()

    if (this.$route.query.directory_origin) {
      SpecialistSearch.Specialist_Directory_Viewed({
        origin: this.$route.query.directory_origin
      })
    }
  },
  destroyed () {
    this.$loader.handleRemoveLoader()
  },
  methods: {
    reset () {
      this.$store.dispatch('filtersV2/cleanResults')

      const selection = this.$store.getters['filtersV2/query']

      Object.keys(selection).forEach((key) => {
        selection[key] = []
      })

      this.$store.dispatch('filtersV2/setQuery', {
        values: selection,
        cleanedRequest: true
      })

      this.scrollTop()
    },
    /**
     * Overrides
     */
    handleGetData () {
      this.$store.dispatch('filtersV2/getResults', { cleanedRequest: false })
    },
    handleSetPagination () {
      this.$store.commit('filtersV2/SET_PAGINATION', {
        page: this.pagination.page + 1
      })

      this.$store.commit('filtersV2/SET_LOADERS', {
        key: 'newPage',
        value: true
      })
    }
  },
  computed: {
    ...mapGetters({
      results: 'filtersV2/results',
      pagination: 'filtersV2/pagination',
      hasFiltersApplied: 'filtersV2/hasFiltersApplied',
      loading: 'filtersV2/loading',
      loaders: 'filtersV2/loaders'
    }),
    resultsCount () {
      if (this.loading) {
        return null
      }

      if (this.hasFiltersApplied) {
        return this.pagination.filtered
      }

      return this.pagination.unfiltered
    },
    bannerValidation () {
      return (index) => {
        if (index + 1 < 5) {
          return false
        }

        return (index + 1) % 5 === 0
      }
    }
  },
  watch: {
    loading: {
      handler (val) {
        if (val) {
          this.$loader.handleShowLoader({
            lang: this.$store.getters.language.lang,
            type: 'main-transparent'
          })
        }
        const elapsedTime = Date.now() - this.startTime
        if (elapsedTime < 3000) {
          const remainingTime = 3000 - elapsedTime
          setTimeout(() => (this.localLoading = val), remainingTime)
        } else {
          this.localLoading = val
        }
      },
      immediate: true
    },
    localLoading: {
      handler (val) {
        if (!val) {
          this.$loader.handleRemoveLoader()
        }
      },
      immediate: true
    }
  }
}
</script>
